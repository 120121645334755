@import url("../../../constant/variable.css");

.l-card-p {
    margin: auto;
    width: 400px;
    height: 400px;
    border-radius: 20px;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    display: grid;
    background-size: cover !important;
    background-position: center !important;
    background-blend-mode: multiply;
    transition: 0.3s;
}

.l-card-p h2 {
    padding: 30px;
    margin: auto;
    font-size: 35px;
    color: white;
    text-align: center;
}

.l-card-p:hover {
    scale: 1.05;
    transition: 0.3s;
}

.blur {
    -webkit-filter: blur(5px);
    /* Safari 6.0 - 9.0 */
    filter: blur(5px);
}

@media screen and (max-width: 576px) {
    .l-card-p {
        width: 250px;
        height: 250px;
    }
}