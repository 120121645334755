@import url("../../../../constant/variable.css");

.d-floor {
  padding: 0 100px 50px 100px;
  background: var(--primary);
}

.d-floor .f-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.d-floor .f-item p {
  color: var(--primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 125% */
  letter-spacing: -0.48px;
}

.d-floor .f-item .icon {
  color: var(--primary);
}

.d-floor .container {
  display: flex;
  flex-direction: row;
  gap: 90px;
}

.d-floor .left {
  width: 60%;
  height: fit-content;
}

.d-floor .right {
  margin-top: 50px;
  width: 40%;
  padding: 45px;
  border-radius: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: fit-content;
}

.d-floor img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}

.d-floor h2 {
  font-size: 40px;
  position: relative;
  margin: 0;
  top: -30px;
}

@media screen and (max-width: 1200px) {
  .d-floor {
    padding: 0 50px 50px 50px;
  }

  .d-floor .right {
    margin-top: 20px;
  }

  .d-floor .container {
    gap: 45px;
  }

  .d-floor .f-item p {
    color: var(--primary);
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .d-floor h2 {
    padding: 0 20px;
  }
  .d-floor {
    padding: 0 30px 50px 30px;
  }

  .d-floor .left {
    width: 100%;
  }

  .d-floor .right {
    padding: 20px;
    width: auto;
    margin-top: 0px;
  }

  .d-floor .container {
    flex-direction: column;
    gap: 45px;
  }
}

@media screen and (max-width: 576px) {
  .d-floor {
    padding: 0 30px 50px 30px;
  }
  .d-floor h1 {
    font-size: 32px;
    padding: 0 30px;
    top: -20px;
  }
}
