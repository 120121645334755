@import url("../../../constant/variable.css");

.b-head{
    background: var(--primary);
    text-align: center;
    height: 90px;
    display: grid;
}

.b-head h1{
    margin: auto 0px;
    color: white;
}