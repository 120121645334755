@import url("../../../../constant/variable.css");

.h-list {
    padding: 50px 0;
}

.list-card {
    overflow: auto;
    display: grid;
    grid-auto-flow: column;
    gap: 50px;
}

.list-card .content {
    width: 400px;
}

.h-list a {
    text-decoration: none;
    color: var(--primary);
    font-weight: bold;
    transition: 0.3s;
}

.h-list a:hover {
    scale: 1.2;
    transition: 0.3s;
}

.h-list .mobile-2 {
    display: none;
}

.h-list section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}


@media screen and (max-width: 768px) {
    .list-card .content {
        width: 350px;
    }

}

@media screen and (max-width: 576px) {
    .list-card .content {
        width: 300px;
    }

    .h-list .mobile-1 {
        display: none;
    }

    .h-list .mobile-2 {
        display: block;
    }
}