@import url("../../../constant/variable.css");

.b-content {
    padding: 50px 100px;
    display: grid;
    grid-template-columns: 400px auto;
}

.b-content a {
    text-decoration: none;
}

.b-content .b-detail {
    background: var(--base);
    border-radius: 20px;
}

.b-content .b-detail .b-detail-text {
    padding: 10px 25px;
}

.b-content .b-detail .b-detail-text h2,
.b-detail-text p {
    margin: 0 0 5px 0;
}

.b-content .b-detail img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.b-content .b-form {
    padding-left: 40px;
}

.b-content .label-required {
    color: var(--primary);
}

.b-content label.label-required::after {
    content: "*";
    margin-left: 3px;
    color: red;
}

.b-content .b-text {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
}

.b-content .form-group {
    display: grid;
    grid-gap: 10px;
}

.b-content .b-text .form-group input {
    border-radius: 10px;
    height: 35px;
    border: none;
    background: var(--base);
    padding: 0px 10px;
}

.b-content .b-text .form-group input::placeholder {
    font-style: italic;
}

.b-content .b-text .form-group input:focus {
    outline: none;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-style: normal;
}

.b-content .b-form .b-foot {
    margin-top: 15px;
}

.b-content .b-form .b-foot .form-group {
    display: grid;
    grid-template-columns: 20px auto;
    margin-bottom: 10px;
}

.b-content input[type='checkbox'] {
    width: 20px;
    height: 20px;
}

.b-content .b-form .b-foot .form-group label {
    font-size: 13px;
    color: var(--primary);
}

.b-content .b-button {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 25% auto;
    grid-gap: 15px;
}

.b-content .b-button button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--primary);
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s;
}

.b-content .b-button button:enabled {
    cursor: pointer;
}

.b-content .b-button .btn-cancel {
    background: none;
    color: var(--primary);
}

.b-content .b-button .btn-submit {
    background: var(--primary);
    color: white;
}

.b-content .b-button .btn-cancel:hover:enabled {
    background: var(--primary);
    color: white;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.b-content .b-button .btn-submit:hover:enabled {
    background: white;
    color: var(--primary);
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.b-content .b-button .btn-submit:disabled {
    background: var(--base);
    border: none;
    color: var(--primary);
    transition: 0.3s;
}

.b-content .b-button .btn-cancel:disabled {
    background: var(--base);
    border: none;
    color: var(--primary);
    transition: 0.3s;
}

.b-content div .error {
    font-size: 13px;
    color: #dc3545;
    margin: -5px 0;
}

.b-content .error-foot {
    margin: -5px 0 10px 0 !important;
}

@media screen and (max-width: 1200px) {
    .b-content {
        padding: 50px 50px;
        grid-template-columns: 350px auto;
    }

    .b-content .b-detail img {
        width: 350px;
        height: 350px;
    }
}

@media screen and (max-width: 992px) {
    .b-content {
        display: block;
        margin-bottom: 50px;
    }

    .b-content .b-detail img {
        width: 100%;
        height: 350px;
    }

    .b-content .b-form {
        margin-top: 40px;
        padding: 0;
    }

    .b-content .b-detail .b-detail-text {
        padding: 20px 25px;
    }
}

@media screen and (max-width: 768px) {
    .b-content {
        padding: 50px 30px;
    }

    .b-content .b-detail img {
        width: 100%;
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    .b-content .b-text {
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto;
    }

    .b-content .b-button {
        grid-template-columns: 30% auto;
    }
}