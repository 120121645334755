@import url("../../constant/variable.css");

.l-card{
    height: fit-content;
    border-radius: 20px;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
}

.l-card .l-content{
    padding: 20px;
    color: var(--primary);
}

.l-card .l-head h2{
    color: black;
    font-size: 20px;
}

.l-card .l-head p{
    display: flex;
    font-size: 16px;
    margin: 10px 0;
}

.l-card .l-head p span{
    color: black;
}

.l-card img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px 20px 0 0;
}

.l-card .l-detail{
    display: flex;
    align-content: space-between;
}

.l-card .l-detail p{
    margin: 0;
    flex-grow: 1;
    display: flex;
}

.l-card .l-detail p span{
    margin-right: 0.2em;
    color: black;
}

.l-card .l-foot{
    margin-top: 10px;
    display: flex;
}


.l-card .l-foot p, button{
    flex-grow: 1;
}

.l-card .l-foot p{
    font-size: 18px;
    font-weight: 600;
    margin: 0 15px 0 0;
    align-self: center;
}

.l-card .l-foot button{
    cursor: pointer;
    width: 150px;
    height: 45px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    background: var(--primary);
    color: white;
    border: none;
    transition: 0.3s;
}

.l-card .l-foot button:hover{
    background: none;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: 0.3s;
}

@media screen and (max-width: 576px) {
    .l-card .l-foot{
        margin-top: 10px;
        display: block;
    }

    .l-card .l-foot button{
        margin-top: 20px;
    }

    .l-card img{
        width: 100%;
        height: 200px;
    }
}

