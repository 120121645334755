@import url("../../../../constant/variable.css");

.h-denah p {
  font-size: 18px;
  margin: 10px 0;
  font-weight: 500;
}

.h-denah h2 {
  font-size: 40px;
  margin: 0;
  padding: 0;
}

.h-denah {
  padding: 50px 100px;
  background: var(--primary);
  overflow: auto;
}

.h-denah aside {
  display: grid;
  grid-template-columns: 65% auto;
  grid-gap: 40px;
}

.h-denah .content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.h-denah aside .d-left {
  width: 100%;
}

.h-denah aside .d-right {
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: flex-end;
}

.h-denah aside .d-right .box {
  width: fit-content;
  padding: 35px;
  background: #fff;
  border-radius: 10px;
  overflow: auto;
}

.h-denah aside .content {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.h-denah aside h3 {
  margin: 0;
  text-align: right;
  color: #fff;
  font-size: 48px;
}

.h-denah .icon {
  color: var(--primary);
}

.h-denah aside h4 {
  margin: 0;
  color: var(--primary);
  font-size: 24px;
}

.h-denah aside p {
  color: var(--primary);
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .h-denah aside {
    display: grid;
    grid-template-columns: 55% auto;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 992px) {
  .h-denah {
    padding: 50px 50px;
  }

  .h-denah aside h3 {
    font-size: 25px;
  }

  .h-denah aside .d-right {
    display: flex;
    flex-direction: column;
    gap: 35px;
    /* align-items: start; */
  }

  .h-denah aside {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
  }

  .h-denah .content {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
  .h-denah .content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px 10px;
  }

  .h-denah aside h3{
    text-align: left;
  }

  .h-denah {
    padding: 50px 30px;
  }

  .h-denah aside .d-right .box {
    padding: 20px;
  }
}
