@import url("../../../constant/variable.css");

.home .h-promotion {
    margin-top: 50px;
    background: var(--base);
}

.home .h-promotion .content{
    display: flex;
    justify-content: space-between;
}

.home .h-promotion .content .left{
    margin-right: 50px;
}

.home .h-promotion .content .image{
    width: 500px;
    height: 500px;
    border-radius: var(--border20);
}

@media screen and (max-width: 1200px) {
    .home .h-promotion .content .image{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 992px) {
    .home .h-promotion .content .image{
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 768px) {
    .home .h-promotion .content .left{
        margin-right: 0;
    }

    .home .h-promotion .content .image{
        object-fit: cover;
        width: 100%;
        height: 400px;
        margin-bottom: 25px;
    }

    .home .h-promotion .content{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 576px) {
    .home .h-promotion .content .image{
        height: 300px;
    }
}