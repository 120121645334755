.h-visual{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    overflow: auto;
}

.visual-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
}

.visual-content{
    max-width: 800px;
    min-width: 300px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.visual-content > h3 {
    padding: 0;
    margin: 0;
    color: var(--primary);
}

.h-visual-content > img {
    max-width: 600px;
    width: 100%;
    border-radius: var(--border20);
}