@import url("../../../../constant/variable.css");

.brosur{
    padding: 81px 100px;
    text-align: center;
}

.brosur h2{
    margin: 0;
}

.brosur h3{
    margin: 40px 0;
    font-weight: 600;
}

@media screen and (max-width: 992px){
    .brosur{
        padding: 81px 50px;
    }
}

@media screen and (max-width: 576px){
    .brosur h2{
        font-size: 45px;
    }
    
    .brosur h3{
        font-size: 20px;
    }
    .brosur{
        padding: 81px 30px;
    }
}

