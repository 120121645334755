@import url("../../../../constant/variable.css");

.h-head{
    display: grid;
}

.h-head img{
    margin: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 450px;
}

.h-head p{
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    color: white;
}

.h-head .h-point{
    display: flex;
    align-content: space-between;
    background: var(--primary);
    padding: 0 100px;
}

.h-head .box{
    flex-grow: 1;
    align-self: center;
}

.h-head .vl {
    margin: 40px 0;
    border-left: 1px solid white;
    height: 50px;
}


@media screen and (max-width: 1300px) {
    .h-head p{
        font-size: 16px;
    }    
}

@media screen and (max-width: 1200px){
    .h-head img{
        height: 350px;
    }
    .h-head .h-point{
        padding: 20px 50px;
        display: grid;
        gap: 20px 20px;
        grid-template-columns: auto auto;
    }

    .h-head .no-grid{
        grid-column-start: span 2;
    }

    .h-head .vl{
        display: none;
    }

    .h-head p{
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }
}

@media screen and (max-width: 576px){
    .h-head .h-point{
        gap: 0;
        padding: 0px 50px;
        display: flex;
        flex-direction: column;
    }

    .h-head .vl{
        display: none;
    }

    .h-head p{
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        margin: 15px 0;
    }

    .h-head img{
        height: 250px;
    }
}