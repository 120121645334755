@import url("../../../constant/variable.css");

.faq .question-group{
    display: grid;
    grid-template-columns: auto 30px;
    width: auto;
    border-radius: 10px;
    background: var(--base);
    padding: 15px;
}

.faq .question-group p{
    padding-left: 10px;
    margin-left: 30px;
    display: list-item;
    list-style-type: disc;
}

.faq .question-group span{
    width: 20px;
    padding: 0 5px;
    margin: auto 0;
    cursor: pointer;
}

.faq .answer{
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.faq .active{
    padding: 20px 20px 20px 20px;
    transition: 0.3s;
}

.faq .rotate{
    transform: rotate(180deg);
    transition: 0.3s;
}

.faq .back{
    transform: rotate(0deg);
    transition: 0.3s;
}