@import url("../../../../constant/variable.css");

.bg-base{
    /* background: var(--base); */
}

.bg-white{
    background: white;
}

.d-area h2{
    font-size: 40px;
    margin: 0;
    padding: 0 100px;
    position: relative;
    top: 25px;
}

.d-area h3{
    font-size: 1.8em;
}

.d-area p{
    font-size: 18px;
}

.d-area .d-bg{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
}

.d-area .description{
    padding: 40px 100px 100px 100px;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .d-area .d-bg{
        height: 550px;
    }

    .d-area h2{      
        padding: 0 50px;
    }

    .d-area .description{
        padding: 40px 50px 100px 50px;
    }
}

@media screen and (max-width: 992px) {
    .d-area .d-bg{
        height: 500px;
    }
}

@media screen and (max-width: 768px) {
    .d-area .d-bg{
        height: 450px;
    }
}

@media screen and (max-width: 576px) {
    .d-area p{
        font-size: 16px;
    }
    .d-area h2{
        font-size: 32px;
        padding: 0 30px;
        top: 20px;
    }
    .d-area .d-bg{
        height: 400px;
    }

    .d-area .description{
        padding: 30px 30px 100px 30px;
    }
}