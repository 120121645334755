@import url("../../../constant/variable.css");

.home .h-reason {
    display: flex;
    
}

.home .h-reason aside p{
    margin: 10px 0 0 0;
    text-align: left;
}

.home .h-reason .image {
    width: 500px;
    height: 500px;
    margin-right: 50px;
    border-radius: var(--border20);
}

.home .h-reason .h-list{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
}

.home .h-reason .h-item{
    display: grid;
    grid-template-columns: 35px auto;
}

.home .h-reason .h-item p{
    font-size: 18px;
    margin: 0;
}

.home .h-reason .h-item .icon{
    color: var(--primary);
}

@media screen and (max-width: 1200px) {
    .home .h-reason .image {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 992px) {
    .home .h-reason .h-item p{
        font-size: 16px;
    }

    .home .h-reason .image {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 768px) {
    .home .h-reason .image {
        object-fit: cover;
        width: 100%;
        height: 400px;
        margin-bottom: 25px;
        margin-right: 0;
    }
    .home .h-reason {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .home .h-reason .image {
        height: 300px;
    }
}