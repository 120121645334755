@import url("../../../../constant/variable.css");

.h-location {
  background: linear-gradient(93deg, #3a6b35 45%, rgba(58, 107, 53, 0) 70%);
  position: relative;
}

.h-location .container {
  width: 100%;
  display: "flex";
  flex-direction: "column";
  gap: 30px;
}

.h-location .maps {
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  object-fit: cover;
  height: 100%;
  z-index: -1;
}

.h-location h2 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.h-location .gradient-text {
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  background: linear-gradient(91deg, #b78f37 0%, #efc273 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h-location h3 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 520px;
}

& .location-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
}

@media screen and (max-width: 992px) {
  .h-location .gradient-text {
    font-size: 56px;
  }

  .h-location h2 {
    font-size: 30px;
  }

  .h-location h3 {
    font-size: 14px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  & .location-content {
    flex-direction: column;
  }

  .h-location .maps {
    bottom: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .h-location {
    background: linear-gradient(180deg, #3a6b35 60%, rgba(58, 107, 53, 0) 90%);
    position: relative;
  }
}

@media screen and (max-width: 576px) {
}
