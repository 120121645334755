@import url("../../../constant/variable.css");

.testi .left h2 {
    font-size: 2em;
    color: var(--primary);
    margin: 0 0 30px 0;
}

.testi .left .t-text {
    display: grid;
    grid-gap: 15px;
}

.testi .left .t-group {
    display: grid;
    grid-gap: 10px;
}

.testi .left .t-text button {
    background: var(--primary);
    color: white;
    border: none;
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}

.testi .left .t-text button:hover:enabled {
    background: white;
    color: var(--primary);
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.testi .left .t-text button:disabled {
    cursor: default;
    background: var(--base);
    border: none;
    color: var(--primary);
    transition: 0.3s;
}

.testi .left .t-group input,
textarea {
    border-radius: 10px;
    border: none;
    background: var(--base);
}

.testi .left .t-group input:focus {
    outline: none;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.testi .left .t-group label {
    color: var(--primary);
}

.testi .left .t-checkbox {
    display: flex;
}

.testi .left .t-checkbox label {
    margin-left: 10px;
    color: var(--primary);
}

.testi .left .t-checkbox input[type='checkbox'] {
    width: 20px;
    height: 20px;
}

.testi .left .t-group .error {
    font-size: 13px;
    color: #dc3545;
    margin: -5px 0;
}

.testi .left .t-group textarea {
    padding: 10px;
    height: 175px;
    font-family: sans-serif;
}

.testi .left .t-group input {
    height: 35px;
    padding: 0px 10px;
}

.testi .left .t-group input::placeholder,
textarea::placeholder {
    font-style: italic;
}