footer {
  padding: 30px 100px 0px 100px;
  box-sizing: border-box;
  overflow: auto;
  box-sizing: border-box;
}

footer .f-right .f-box-left {
  display: contents;
}

footer .f-right .f-box-right {
  display: contents;
}

footer aside {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

footer aside .f-left p {
  margin: 10px 0 0 0;
  font-size: 18px;
  width: 400px;
  color: rgba(0, 0, 0, 0.5);
}

footer aside .f-left img {
  height: 120px;
}

footer aside .f-right {
  display: flex;
}

footer aside .f-right h2 {
  font-size: 18px;
  margin: 0;
}

footer aside .f-right ul {
  list-style-type: none;
  padding: 0;
}

footer aside .f-right li {
  margin: 25px 0;
}

footer .bside {
  text-align: center;
}

footer .bside p {
  margin: 60px 0;
}

footer .f-right a {
  text-decoration: none;
  color: black;
}

.f-box {
  flex-grow: 1;
  margin-left: 60px;
}

@media screen and (max-width: 1200px) {
  footer aside {
    margin-bottom: 30px;
  }

  footer {
    padding: 30px 80px 0px 80px;
    box-sizing: border-box;
    overflow: auto;
  }

  footer aside {
    display: block;
    text-align: center;
  }

  footer aside .f-left p {
    font-size: 16px;
    text-align: center;
    margin: 5px auto 20px auto;
  }

  footer aside .f-left img {
    height: 100px;
  }

  footer aside .f-right h2 {
    font-size: 16px;
  }

  footer aside .f-right li {
    margin: 10px 0;
    font-size: 14px;
  }

  footer .bside p {
    margin: 30px 0;
    font-size: 16px;
  }

  .f-box {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  footer {
    padding: 30px 50px 0px 50px;
  }
  footer aside .f-left p {
    width: 100%;
  }
  footer .f-right {
    align-content: space-between;
  }

  footer .f-right .f-box-left {
    display: block;
    flex-grow: 1;
  }

  footer .f-right .f-box-right {
    display: block;
    flex-grow: 1;
  }
}

@media screen and (max-width: 526px) {
  footer aside .f-left img {
    width: 100%;
    height: auto;
  }
}
