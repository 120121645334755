@import url("../../constant/variable.css");

nav{
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    height: 90px;
}

nav .active{
    font-weight: bold;
}

nav ul{
    width: 450px;
    padding-right: 50px;
    display: flex;
    align-content: space-between;
}

nav ul li{
    flex-grow: 1;
    list-style-type: none;
    text-align: center;
}

nav ul li a:hover{
    font-weight: bolder;
}

nav a{
    align-self: center;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

nav .n-contact{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 20px;
    background: none;
    width: 150px;
    height: 45px;
    transition: 0.3s;
}

nav .n-contact:hover{
    background-color: var(--primary);
    color: white;
    transition: 0.3s;
}

nav .n-contact img{
    flex-grow: 1;
    width: 20px;
}

nav .n-contact div{
    display: inline-flex;
    align-items: center;
}

nav .menu-icon{
    display: none;
    background: none;
    border: none;
    color: var(--primary);
}

nav .nav-menu{
    display: flex;
    flex-direction: row;
}

.nav-float{
    padding: 0;
    position: fixed;
    transform: translateY(-50%);
    top: 40vh;
    right: 20px;
    display: grid;
    grid-template-columns: auto auto;
    transition: 0.3s;
    z-index: 100;
}

.nav-float .float-desc .float-box{
    margin: 0;
    border-radius: 30px 0 0 30px;
    display: grid;
    height: 54px;
    background: var(--primary);
    opacity: 0;
    transition: 0.3s;
}

.nav-float .float-desc p{
    margin: auto 20px;
    color: white;
}

.nav-float .deactive{
    display: none;
}

.nav-float .float-desc .active{
    opacity: 1;
    transition: 0.3s;
}

.nav-float .float-icon{
    padding: 0;
    display: grid;
    width: 50px;
    background: rgba(46, 46, 46, 0.5);
    border-radius: 30px;
}

.nav-float .btn-icon{
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    color: white;
    padding: 8px;
    transition: 0.3s;
    width: 50px;
    overflow: hidden;
}


.nav-float .btn-icon:hover{
    background: var(--primary);
    border-radius:  0 30px 30px 0;
    transition: 0.3s;
    width: auto;
}

.nav-float .btn-up{
    display: grid;
    padding: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background: var(--primary);
    color: white;
    margin: 8px auto;
    font-size: 25px;
}

.nav-float .btn-up svg{
    margin: auto;
}




@media screen and (max-width: 1000px) {
    nav{
        padding: 0;
    }
    nav .l-click{
        margin-left: 80px;
    }

    nav .r-click{
        margin-right: 80px;
    }

    nav ul{
        padding: 0;
        width: 230px;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    nav ul li{
        margin: 10px 0;
    }

    nav .nav-menu{
        display: flex;
        position: absolute;
        background: white;
        flex-direction: column;
        width: 100%;
        height: 100%;
        top: -100%;
        transition: all 0.5s ease;
        opacity: 0;
    }

    nav .nav-menu.active{
        opacity: 1;
        top: 90px;
        transition: all 0.5s ease-in;
    }

    nav .menu-icon {
        text-align: end;
        display: block;
    }
}

@media screen and (max-width: 576px) {
    nav .l-click{
        margin-left: 30px;
    }

    nav .r-click{
        margin-right: 30px;
    }

    .nav-float .btn-icon:hover{
        border-radius: 30px;
    }

    .nav-float .float-desc{
        display: none;
    }

    .nav-float .float-desc .active{
        opacity: 0;
    }
}
