@import url("../../../constant/variable.css");

.home .griya-paresia {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.home .griya-paresia .griya-paresia__head {
  padding-top: 95px;
  position: relative;
}

.home .griya-paresia .griya-paresia__img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

.home .gradient-text {
  font-size: 96px;
  font-style: italic;
  font-weight: 800;
  background: linear-gradient(91deg, #b78f37 0%, #efc273 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home .griya-paresia .griya-paresia__content {
  display: flex;
  flex-direction: row;
  gap: 56px;
}

.home .griya-paresia .griya-paresia__sub-content {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.home .griya-paresia .griya-paresia__sub-content-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.home .griya-paresia .griya-paresia__sub-content-1 p {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.home .griya-paresia .griya-paresia__img_2 {
  max-width: 168px;
  max-height: 168px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
}

@media screen and (max-width: 1200px) {
  .home .griya-paresia {
    flex-direction: column;
    gap: 36px;
  }

  .home .griya-paresia .griya-paresia__head {
    padding-top: 80px;
  }

  .home .gradient-text {
    font-size: 85px;
  }

  .home .griya-paresia .griya-paresia__content {
    gap: 36px;
  }

  .home .griya-paresia .griya-paresia__sub-content {
    gap: 12px;
    flex-wrap: wrap;
  }

  .home .griya-paresia .griya-paresia__img_2 {
    max-width: 150px;
    max-height: 150px;
  }
}

@media screen and (max-width: 992px) {
  .home .griya-paresia .griya-paresia__head {
    padding-top: 65px;
  }

  .home .gradient-text {
    font-size: 60px;
  }

  .home .griya-paresia .griya-paresia__sub-content-1 p {
    font-size: 16px;
  }

  .home .griya-paresia {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .home .griya-paresia .griya-paresia__content {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .home .griya-paresia .griya-paresia__img_2 {
    max-width: 120px;
    max-height: 120px;
  }
}

@media screen and (max-width: 768px) {
  .home .griya-paresia .griya-paresia__head {
    padding-top: 36px;
  }

  .home .griya-paresia .griya-paresia__img {
    height: 312px;
  }

  .home .gradient-text {
    font-size: 32px;
  }

  .home .griya-paresia .griya-paresia__content {
    flex-direction: column;
  }

  .home .griya-paresia .griya-paresia__sub-content {
    width: 100%;
    flex-wrap: nowrap;
  }

  .home .griya-paresia .griya-paresia__sub-content-1 p {
    font-size: 16px;
  }

  .home .griya-paresia {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .home .griya-paresia .griya-paresia__img_2 {
    flex-basis: 1;
    height: auto;
    min-width: 0;
  }
}
