@import url("../../constant/variable.css");

.faq{
    padding: 100px;
    text-align: center;
    display: grid;
}


.faq h1{
    color: var(--primary);
}

.faq .question-box{
    width: fit-content;
    text-align: left;
    margin: 60px auto;
}


@media screen and (max-width: 1200px) {
    .faq{
        padding: 50px;
    }
}


@media screen and (max-width: 768px) {
    .faq{
        padding: 30px;
    }
}
