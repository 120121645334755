@import url("../../constant/variable.css");

.overlay{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: grid;
    transition: 0.3s;
}

.modal-success{
    border-radius: 20px;
    margin: auto;
    display: grid;
    width: 600px;
    height: 400px;
    text-align: center;
    background: white;
}

.overlay .success{
    color: green;
}

.overlay .failed{
    color: red;
}

.modal-success .modal-center{
    margin: auto 0;
}

.modal-success .modal-center p{
    font-size: 18px;
    margin: 20px 0;
}

.modal-success .modal-center h4{
    font-size: 24px;
    margin: 20px 0;
}

.modal-success .modal-center button{
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    width: 100px;
    height: 50px;
    border-radius: 20px;
    border: none;
    background: var(--base);
    transition: 0.3s;
    color: black;
}

.modal-success .modal-center button:hover{
    color: white;
    background: var(--primary);
    transition: 0.3s;
}

.modal-success p{
    color: black;
}

@media screen and (max-width: 768px) {
    .modal-success{
        width: 500px;
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    .modal-success{
        width: 80%;
        height: 300px;
    }
}