@import url("../../../constant/variable.css");

.testi .right h1, .testi .right h2{
    color: var(--primary);
    margin: 0 0 30px 0;
}

.testi .right .t-box{
    height: 530px;
    overflow: auto;
}

