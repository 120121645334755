@import url("../../../../constant/variable.css");

.d-map{
    padding: 50px 100px;
}

.d-map h2{
    margin: 0;
    font-size: 35px;
}

.d-map .content{
    border-radius: 20px;
    display: grid;
    grid-template-columns: 600px auto;
    background: var(--base);
}

.d-map iframe{
    border-radius: 20px;
    width: 600px;
    height: 550px;
}

.d-map .detail{
    padding: 35px;
}

.d-map .detail p{
    margin-top: 10px;
    margin-bottom: 20px;
}

.d-map button{
    cursor: pointer;
    background: none;
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: var(--primary);
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;
}

.d-map button:hover{
    color: white;
    background: var(--primary);
    transition: 0.3s;
}

@media screen and (max-width: 1200px) {
    .d-map{
        padding: 50px 50px;
    }

    .d-map .content{
        grid-template-columns: 400px auto;
    }

    .d-map iframe{
        width: 400px;
        height: 400px;
    }

    .d-map .detail{
        padding: 25px;
    }
}

@media screen and (max-width: 992px) {
    .d-map .content{
        grid-template-columns: auto;
    }

    .d-map iframe{
        width: 100%;
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .d-map iframe{
        height: 350px;
    }
}

@media screen and (max-width: 576px) {
    .d-map iframe{
        height: 300px;
    }
    .d-map{
        padding: 50px 30px;
    }
}