.testi{
    display: grid;
    grid-template-columns: 50% auto;
    padding: 30px 100px;
}

.testi .left{
    padding-right: 30px;
}

.testi .right{
    padding-left: 30px;
}

@media screen and (max-width: 992px) {
    .testi{
        padding: 30px 50px;
    }
}

@media screen and (max-width: 768px) {
    .testi{
        display: flex;
        flex-direction: column-reverse;
    }

    .testi .left{
        padding-right: 0;
    }
    
    .testi .right{
        padding-left: 0;
        margin-bottom: 50px;
    }

}

@media screen and (max-width: 576px) {
    .testi{
        padding: 30px 30px;
    }
}