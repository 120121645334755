@import url("../../../constant/variable.css");

.home .h-video {
    background: var(--base);
    margin-bottom: 50px;
}

.home .h-video .content {
    display: flex;
    justify-content: space-between;
}

.home .h-video .content .video{
    width: 670px;
    height: 350px;
    border: none;
    border-radius: var(--border20);
    margin-left: 50px;
}

@media screen and (max-width: 1200px) {
    .home .h-video .content .video{
        width: 570px;
        height: 300px;
    }
}

@media screen and (max-width: 992px) {
    .home .h-video .content {
        display: block;
    }

    .home .h-video .content .video{
        margin-top: 50px;
        margin-left: 0;
        width: 100%;
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .home .h-video .content .video{
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    .home .h-video .content .video{
        height: 250px;
    }
}