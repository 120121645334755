.c-customer{
    display: flex;
}

.c-customer img{
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-right: 20px;
}

.c-customer p{
    font-size: 14px;
    margin: 0 !important;
    padding-right: 10px;
}

.c-customer h3{
    margin-top: 0;
    margin-bottom: 15px;
}