@import url("../../constant/variable.css");

.loading{
    height: 80vh;
    display: grid;
}

.loading .content{
    margin: auto;
    text-align: center;
}

.loading .content img{
    width: auto;
    height: 100px;
    margin-bottom: 10px;
}

.loading .content .loading-dot > div{
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: var(--baseLogo);
    border-radius: 50%;
    display: inline-block;
    animation: 1.5s bounce infinite ease-in-out both;
}

.loading .content .loading-dot .bounce1{
   animation-delay: -0.30s;
}

.loading .content .loading-dot .bounce2{
   animation-delay: -0.15s;
}

@keyframes bounce{
    0%, 80%, 100%{
        transform: scale(0);
    }
    40%{
        transform: scale(1);
    }
}