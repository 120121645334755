@import url("../../constant/variable.css");

.e-notfound{
    padding: 0 100px;
    display: grid;
    text-align: center;
    color: var(--primary);
    height: 100vh;
}

.e-notfound .center{
    margin: 0 auto;
}

.e-notfound button{
    margin-top: 20px;
    width: 280px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid var(--primary);
    color: var(--primary);
    background: none;
    cursor: pointer;
    transition: 0.3s;
}

.e-notfound button:hover{
    color: white;
    background: var(--primary);
    transition: 0.3s;
}



@media screen and (max-width: 1200px) {
    .e-notfound{
        padding: 0 50px;
    }
}


@media screen and (max-width: 576px) {
    .e-notfound{
        padding: 0 30px;
    }
}

@media screen and (max-width: 340px) {
    .e-notfound button{
        width: 100%;
    }
}