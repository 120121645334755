@import url("../../../constant/variable.css");

.p-hero .p-head {
    display: grid;
    width: 100%;
    height: 450px;
    text-align: center;
    background-position: center;
    background-size: cover;
}

.p-hero .p-head h1 {
    font-size: 50px;
    font-weight: 600;
    color: white;
    margin: auto 0px;

}

.p-hero .p-nav {
    display: grid;
    position: relative;
    background: var(--primary);
    width: fit-content;
    height: 100px;
    border-radius: 20px;
    margin: 0 auto;
    top: -45px;
    padding: 0 20px;
    
}

.p-hero .p-nav button {
    cursor: pointer;
    color: var(--primary);
    border: 1px solid white;
    border-radius: 10px;
    font-size: 16px;
    background: white;
    margin: 25px 20px;
    width: 250px;
    height: 50px;
    transition: 0.3s;
}

.p-hero .p-nav button:hover {
    color: white;
    background: var(--primary);
    transition: 0.3s;
}

.p-hero .p-button {
    font-style: italic;
    font-weight: 500;
    padding: 10px 20px;
    margin: auto;
    display: grid;
    grid-template-columns: 35px auto;
    letter-spacing: 0.1em;
}

.p-hero .p-button .p-text {
    opacity: 0.7;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
}

.p-hero .p-button span {
    margin: auto 0;
}

.p-hero .p-button .p-head-content {
    margin: auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-hero .p-nav .p-option {
    visibility: hidden;
    opacity: 0;
    position: relative;
    top: -15px;
    width: 210px;
    margin: 0 auto;
    padding: 15px 20px;
    background: white;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transition: 0.3s;
}

.p-hero .p-nav .p-active{
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
}

.p-hero .p-nav .p-item{
    cursor: pointer;
    padding: 10px 0;
    color: var(--primary);
}

.p-hero .p-nav .p-item:hover{
    font-weight: bold;
}