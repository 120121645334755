@import url("../../../../constant/variable.css");

.h-detail{
    padding: 60px 100px;
}

.h-detail p{
    font-size: 20px;
    margin: 10px 0;
    font-weight: 500;
}

.h-detail h1{
    font-size: 40px;
    margin: 0;
    padding: 0;
}

.h-detail aside{
    margin-top: 35px;
    display: grid;
    grid-template-columns: 45% auto;
    grid-gap: 40px;
}

.h-detail aside .l-img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 774px;
}

.h-detail aside .r-img{
    object-fit: cover;
    object-position: center;
    width: 90%;
    height: 520px;
    margin: 0;
}

.h-detail aside h2{
    font-size: 40px;
    margin: 40px 0;
}

.h-detail .r-content{
    display: flex;
}

.h-detail .r-content .control{
    padding-left: 30px;
    margin: 0;
    align-self: flex-end;
}

.h-detail .r-content .control li{
    list-style-type: none;
    margin-top: 10px;
    width: 15px;
    height: 15px;
    background: #a5a5a5;
    border-radius: 50%;
    border: 3px solid #fff;
    cursor: pointer
}

.h-detail .r-content .control .active{
    background: black;
}

.h-detail .r-detail{
    display: flex;
    align-content: space-between;
    padding-right: 10%;
}

.h-detail .r-detail p{
    font-size: 24px;
    margin: auto 0;
}

.h-detail .button-group{
    width: 360px;
    display: flex;
    justify-content: space-between;
}

.h-detail .r-detail button{
    width: 176px;
    height: 55px;
    font-size: 24px;
    border: 1px solid var(--primary);
    background: none;
    border-radius: 10px;
    color: var(--primary);
    cursor: pointer;
    transition: 0.3s;
}

.h-detail .r-detail button:hover{
    color: white;
    background: var(--primary);
    transition: 0.3s;
}

.h-detail .box{
    flex-grow: 1;
}

@media screen and (max-width: 1200px) {
    .h-detail aside .l-img{
        height: 520px;
    }
    
    .h-detail aside .r-img{
        height: 420px;
    }

    .h-detail .r-detail{
        padding-right: 0;
    }
}

@media screen and (max-width: 992px) {
    .h-detail{
        padding: 60px 50px;
    }
    .h-detail aside .l-img{
        height: 420px;
    }
    
    .h-detail aside .r-img{
        height: 321px;

    }

    .h-detail .r-detail p{
        font-size: 20px;
        margin: auto 0;
    }

    .h-detail aside p{
        font-size: 14px;
    }
    .h-detail aside h2{
        margin: 20px 0;
        font-size: 35px;
    }

    .h-detail .r-detail button{
        width: 150px;
        height: 50px;
        font-size: 20px;
    }

    .h-detail .r-content .control{
        padding-left: 20px;
        margin: 0;
        align-self: flex-end;
    }
}

@media screen and (max-width: 890px) {
    .h-detail .button-group{
        width: 310px;
    }

    .h-detail{
        padding: 60px 30px;
    }
    .h-detail aside .l-img{
        height: 212px;
    }

    .h-detail aside{
        display: block;
    }
    
    .h-detail aside .r-img{
        width: 100%;
        height: 171px;

    }

    .h-detail .r-detail{
        padding-right: 0;
    }
    
    .h-detail .r-detail button{
        width: 150px;
        height: 45px;
        font-size: 20px;
    }

    .h-detail .r-content{
        display: block;
    }

    .h-detail .r-content .control{
        padding: 0;
        display: inline-flex;
    }
    .h-detail .r-content .control li{
        margin-right: 10px;
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width: 576px) {
    .h-detail .button-group{
        flex-direction: column;
        align-items: flex-end;
        height: 100px;
        width: fit-content;
    }
}