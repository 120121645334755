@import url("../../../../constant/variable.css");

.t-jumbotron{
    padding: 60px 100px;
    background-color: var(--primary);
    font-size: 20px;
    color: white;
}

.t-jumbotron p, h2{
    margin: 5px;
}

.t-jumbotron a{
    color: white;
    text-decoration: none;
}

.t-jumbotron a:hover{
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.t-jumbotron section{
    display: flex;
    justify-content: space-between;
}

.t-jumbotron .t-box{
    display: flex;
    align-content: space-between;
    overflow: auto;
    padding-bottom: 30px;
}

.t-jumbotron .t-card{
    flex-grow: 1;
    margin-right: 20px;
}

.t-jumbotron .t-md{
    margin-top: 10px;
    display: none;
}

@media screen and (max-width: 992px){
    .t-jumbotron{
        padding: 60px 50px;
    }

    .t-jumbotron section a{
        display: none;
    }

    .t-jumbotron .t-md{
        display: block;
    }
}

@media screen and (max-width: 576px){
    .t-jumbotron .t-md{
        font-size: 16px;
    }
}