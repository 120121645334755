@import url("../../../../constant/variable.css");

.d-general{
    padding: 60px 100px;
    display: flex;
}

.d-general img{
    object-fit: cover;
    object-position: center;
    width: 593px;
    height: 590px;
    border-radius: 10px;
}

.d-general .d-content{
    padding: 0 0 0 50px;
    align-self: flex-end;
}

.d-general .d-content .head{
    color: var(--primary);
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.d-general .d-content h1{
    font-size: 36px;
    margin: 10px 0;
    padding: 0;
}

.d-general .d-address p{
    display: flex;
    margin: 0;
    padding: 0;
}

.d-general .d-detail{
    margin-top: 40px;
}

.d-general .d-detail h2{
    font-size: 16px;
    margin-bottom: 15px !important;
    padding: 0;
    margin: 0;
}
.d-general .d-detail p{
    font-size: 16px;
    margin-bottom: 15px !important;
}

.d-general .d-description{
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 10px 30px;
    margin: 40px 0;
}

.d-description p{
    color: var(--primary);
    margin: 0;
    display: flex;
}

.d-general .icon{
    margin-right: 0.2em;
    color: black;
}

.d-general .btn-book{
    width: 250px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    background: var(--base);
    color: var(--primary);
    transition: 0.5s;
}

.d-general .btn-book:hover{
    border: 1px solid var(--primary);
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}

@media screen and (max-width: 1380px) {
    .d-general img{
        width: 493px;
        height: 490px;
    }
}

@media screen and (max-width: 1200px) {
    .d-general{
        padding: 60px 50px;
        display: flex;
    }
    .d-general img{
        width: 423px;
        height: 420px;
    }
}

@media screen and (max-width: 992px) {
    .d-general{
        padding: 60px 100px;
        display: block;
    }
    .d-general img{
        width: 100%;
    }

    .d-general .d-content{
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .d-general{
        padding: 60px 50px;
        display: block;
    }
    .d-general img{
        width: 100%;
        height: 80vw;
    }

    .d-general .d-content{
        padding: 10px 0;
    }
}

@media screen and (max-width: 576px) {
    .d-general{
        padding: 60px 30px;
        display: block;
    }

    .d-general .d-description{
        grid-template-columns: max-content max-content;
    }

    .d-general .d-content h1{
        font-size: 33px;
        max-width: 240px;
    }
}