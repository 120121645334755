:root {
  --primary: #3a6b35;
  --base: #efefed;
  --wa: #00e676;
  --baseLogo: #b78f37;
  --border20: 20px;
}

.tagline {
  font-size: 20px;
  margin: 10px 0;
  font-weight: 500;
}

.head-1 {
  font-size: 40px;
  margin: 0;
  padding: 0;
}

.para-1 {
  font-size: 20px;
}

.button-1 {
  width: 240px;
  height: 70px;
  background: none;
  border: 1px solid var(--primary);
  border-radius: 10px;
  color: var(--primary);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.button-1:hover {
  background: var(--primary);
  color: white;
  transition: 0.3s;
}

.button-1-2 {
  /* width: 240px; */
  padding-left: 40px;
  padding-right: 40px;
  height: 70px;
  background: #e9ae44;
  border: 1px solid #e9ae44;
  border-radius: 10px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.button-1-2:hover {
  background: none;
  border: 1px solid #e9ae44;
  color: #e9ae44;
  transition: 0.3s;
}

.button-2 {
  width: 240px;
  height: 70px;
  background: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.button-2:hover {
  background: none;
  color: var(--primary);
  transition: 0.3s;
}

.pad-1 {
  padding: 50px 100px;
}

.pad-3 {
  padding: 36px 100px;
}

.pad-2 {
  padding: 0 100px;
}

.p-content > p {
  padding-top: 10px;
  font-size: 18px;
  text-align: justify;
}

@media screen and (max-width: 1200px) {
  .pad-1 {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 992px) {
  .pad-1 {
    padding: 50px 50px;
  }

  .pad-2 {
    padding: 0 50px;
  }

  .pad-3 {
    padding: 36px 50px;
  }

  .p-content > p {
    font-size: 16px;
  }

  .para-1 {
    font-size: 18px;
  }

  .tagline {
    font-size: 18px;
  }

  .button-1 {
    font-size: 18px;
  }

  .button-1-2 {
    font-size: 18px;
  }

  .head-1 {
    font-size: 35px;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
  .pad-1 {
    padding: 50px 30px;
  }

  .pad-2 {
    padding: 0 30px;
  }

  .pad-3 {
    padding: 36px 30px;
  }

  .button-1-2 {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .button-1,
  .button-2 {
    width: 220px;
    height: 60px;
  }
}
