@import url("../../../../constant/variable.css");

.h-spesifikasi {
  width: 100%;
  position: relative;
  background: var(--base);
}

.h-spesifikasi .bg {
  position: absolute;
  right: 0;
  max-width: 828px;
  /* width: 100%; */
  aspect-ratio: 1/1;
  height: 100%;
  z-index: 1;
}

.h-spesifikasi .content {
  position: relative;
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  z-index: 10;
}

.h-spesifikasi .content .head {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.h-spesifikasi .content .head h2 {
  color: #000;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.h-spesifikasi .content .head p {
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  background: linear-gradient(91deg, #b78f37 0%, #efc273 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 104.3%; /* 83.44px */
}

.h-spesifikasi .content .head hr {
  border: 1px solid black;
}

.h-spesifikasi .body {
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
  max-width: 1000px;
  width: 100%;
}

.h-spesifikasi .group {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.h-spesifikasi .max-width-1 {
  max-width: 400px;
}

.h-spesifikasi .group .group-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  width: fit-content;
  gap: 10px;
  padding: 10px;
  background: var(--primary);
  border-radius: 999px;
  color: white;
}

.h-spesifikasi .group .group-title span {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.h-spesifikasi .group h3,
p {
  padding: 0;
  margin: 0;
}

.h-spesifikasi .group h3 {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.h-spesifikasi .group p {
  color: var(--primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h-spesifikasi .group span {
  font-size: 40px;
}

@media screen and (max-width: 1200px) {
  .h-spesifikasi .content {
    padding: 80px 100px;
  }
}

@media screen and (max-width: 992px) {
  .h-spesifikasi .bg {
    height: 50%;
  }

  .h-spesifikasi .group h3 {
    font-size: 25px;
  }

  .h-spesifikasi .content .head p {
    font-size: 56px;
  }

  .h-spesifikasi .body {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 50px 30px;
    margin-bottom: 50px;
  }

  .h-spesifikasi .content {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 768px) {
  .h-spesifikasi .bg {
    display: none;
  }

  .h-spesifikasi .content {
    padding: 50px 50px;
  }
}

@media screen and (max-width: 576px) {
  .h-spesifikasi .content .head {
    margin: 0 auto 50px auto;
  }

  .h-spesifikasi .content .head h2 {
    /* text-align: center; */
    font-size: 32px;
    margin: 0;
  }

  .h-spesifikasi .content {
    padding: 100px 30px;
  }

  .h-spesifikasi .body {
    margin-bottom: 0;
  }
}
