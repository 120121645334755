@import url("../../../../constant/variable.css");

.h-prasarana{
    padding: 50px 100px;
    overflow: auto;
}

.h-prasarana p{
    font-size: 18px;
    margin: 10px 0;
    font-weight: 500;
}

.h-prasarana h2{
    font-size: 40px;
    margin: 0;
    padding: 0;
}

.h-prasarana aside{
    display: grid;
    grid-template-columns: 65% auto;
    grid-gap: 40px;
    margin-top: 40px;
}

.h-prasarana aside .d-left{
    width: 100%;
}

.h-prasarana aside ul{
    padding-left: 20px;
    margin: 0;
}

.h-prasarana aside li{
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    
    .h-prasarana aside li{
        font-size: 16px;
    }
}

@media screen and (max-width: 992px) {
    .h-prasarana{
        padding: 50px 50px;
    }  

    .h-prasarana aside{
        display: block;
    }

    .h-prasarana .content{
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px;
    }
}

@media screen and (max-width: 768px) {
 
}

@media screen and (max-width: 576px) {
    .h-prasarana{
        padding: 50px 30px;
    } 
}