@import url("../../../../constant/variable.css");

.d-promo{
    padding: 75px 100px;
    background: var(--base);
    display: flex;
    align-content: space-between;
}

.d-promo .p-left{
    align-self: center;
}

.d-promo .p-left p{
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.d-promo .p-right{
    text-align: center;
}

.d-promo .p-right button{
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    background: none;
    font-size: 16px;
    transition: 0.3s;
}

.d-promo .p-book{
    border: 2px solid var(--primary);
    color: var(--primary);
}

.d-promo .p-wa{
    border: 2px solid var(--wa);
    color: var(--wa);
}

.d-promo .p-book:hover{
    background: var(--primary);
}

.d-promo .p-wa:hover{
    background: var(--wa);
}

.d-promo .p-right button:hover{
    color: white;
}

.d-promo .box{
    flex-grow: 1;
}

@media screen and (max-width: 1200px) {
    .d-promo{
        padding: 75px 50px;
    }
}

@media screen and (max-width: 992px) {
    .d-promo{
        display: block;
        text-align: center;
    }

    .d-promo .p-left{
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 576px) {
    .d-promo{
        padding: 75px 30px;
    }
}
