@import url("../../constant/variable.css");

.p-hero .p-head{
    display: grid;
    width: 100%;
    height: 450px;
    text-align: center;
    background-position: center;
    background-size: cover;
}

.p-hero .p-head h1{
    font-size: 50px;
    font-weight: 600;
    color: white;
    margin: auto 0px;
    
}

.p-hero .p-nav{
    position: relative;
    background: var(--primary);
    width: fit-content;
    border-radius: 20px;
    margin: 0 auto;
    top: -45px;
    padding: 0 20px;
}


.p-hero .p-nav button:disabled{
    cursor: default;
    color: var(--primary);
    background: white;
    transition: 0.3s;
}

.p-hero .p-content{
    padding: 0 100px 150px 100px;
    display: grid;
    grid-template-columns: 400px 400px 400px;
    grid-gap: 60px;
    justify-content: center;
}

.p-hero .p-property{
    padding: 0 100px 150px 100px;
    display: grid;
    grid-template-columns: 450px 450px;
    grid-gap: 20px;
}

@media screen and (max-width: 1500px) {
    .p-hero .p-content{
        padding: 0 50px 150px 50px;
        grid-gap: 30px;
    }

    .p-hero .p-property{
        padding: 0 50px 150px 50px;
    }
}

@media screen and (max-width: 1370px) {
    .p-hero .p-content{
        grid-template-columns: 400px 400px;
        grid-gap: 60px;
    }

    .p-hero .p-property{
        padding: 0 50px 150px 50px;
    }
}

@media screen and (max-width: 992px) {
    .p-hero .p-content, .p-hero .p-property{
        grid-template-columns: 400px;
    }

    .p-hero .p-nav button{ 
        width: 150px;
    }
}

@media screen and (max-width: 768px) {
    .p-hero .p-nav button{
        margin: 25px 10px;
    }
}

@media screen and (max-width: 576px) {

    .p-hero .p-content, .p-hero .p-property{
        grid-template-columns: auto;
        padding: 0 30px 100px 30px;
    }

    /* .p-hero .p-nav{
        padding: 30px;
        display: grid;
        grid-template-columns: auto;
        grid-gap: 20px;
        top: -100px;
    } */

    .p-hero .p-nav button{
        background: none;
        margin: 0;
        width: 200px;
        height: 50px;
    }
}